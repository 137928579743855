console.log("fortawesome");
require("@rails/ujs").start();
require("@fortawesome/fontawesome-free");
window.jQuery = require("jquery");
require("jquery-ujs");
require("bootstrap");

import "./styles/application.scss";

// console.log("Got The Thing");
